<template>
    <div class="mt-8">
        <v-dialog v-model="dialog" persistent max-width="700">
            <v-card>
              <v-card-title class="text-h5"> Demande de cotation </v-card-title>
                <v-flex class="text-center">
                  <v-progress-circular
                    v-if="loading == true"
                    :size="150"
                    :width="7"
                    color="primary"
                    indeterminate
                  >
                    Patientez ...
                  </v-progress-circular>
                </v-flex>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAjouterOffre" persistent max-width="700">
            <v-card>
            <v-card-title class="text-subtitle-1" v-if="errorAjouterOffre==true || errorAjouterOffre=='year'">{{messageAjouter}}</v-card-title>
              <v-card-text v-if="loading == true && errorAjouterOffre==false">
                <v-flex class="text-center">
                  <v-progress-circular
                    v-if="loading == true"
                    :size="150"
                    :width="7"
                    color="primary"
                    indeterminate
                  >
                    Patientez ...
                  </v-progress-circular>
                </v-flex>
              </v-card-text>
            <v-card-actions  v-if="errorAjouterOffre==true">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialogAjouterOffre">Annuler</v-btn>

                <v-btn v-if="typeDemande=='GAZ'" color="blue darken-1" text @click="modifierOffreAnyWay(offreModifier)">Confirmer</v-btn>
                <v-btn v-if="typeDemande=='ELEC'" color="blue darken-1" text @click="modifierOffreAnyWay(offreModifier)">Confirmer</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            <v-card-actions  v-if="errorAjouterOffre==false">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialogAjouterOffre">Fermer</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <b-alert  v-if="linkFound==false" variant="danger" show>cette offre n'existe plus</b-alert>
        <b-alert  v-if="etat!='activer'" variant="danger" show>cette offre n'est pas activer pour l'instant</b-alert>
        <v-btn class="ml-4" v-if="(typeUser=='admin' || typeUser=='superadmin') && etat=='activer'" @click="desactiverLink($route.query.id)">Désactiver</v-btn>
        <v-btn v-if="(typeUser=='admin' || typeUser=='superadmin')  && etat!='activer'" @click="activerLink($route.query.id)">Activer</v-btn>
        <div v-if="linkFound==true">
            <v-card class="ma-4 d-flex flex-column">
                <v-card-title id="card-title" class="text-h4 text--primary">Informations de la Société</v-card-title>
                <v-card-text class="ma-0 pa-0 container d-flex  flex-row">
                    <v-col class="ma-0 d-flex  container-responsive flex-column" >
                        <span  class="d-flex flex-column text-subtitle-2">Raison Sociale : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.Raison}}</p></span>
                        <span  class="d-flex text-subtitle-2">Siren: <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.siren}}</p></span>
                        <span  class="d-flex text-subtitle-2">Adresse : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.AdressePostal}}</p></span>
                        <span  class="d-flex text-subtitle-2">Code postal :<p class="ma-0 pl-2 black--text font-weight-bold ">{{societe.codePostal}}</p></span>
                        <span  class="d-flex text-subtitle-2">Commune : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.commune}}</p></span>
                        <span  class="d-flex text-subtitle-2">Voie : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.Voie}}</p></span>
                    </v-col>
                    <v-col class="ma-0 d-flex container2-responsive flex-column">
                        <span  class="d-flex text-subtitle-2">Siret : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.siret}}</p></span>
                        <span  class="d-flex text-subtitle-2">Code NAF : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.codeNaf}}</p></span>
                        <span  class="d-flex text-subtitle-2">Code INSEE : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.codeInsee}}</p></span>
                        <span  class="d-flex text-subtitle-2">Capital Social : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.capitalSocial}}</p></span>
                        <span  class="d-flex text-subtitle-2">Numéro RCS : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.RCS}}</p></span>
                        <span  class="d-flex text-subtitle-2">Numéro TVA : <p class="ma-0 pl-2 black--text font-weight-bold">{{societe.TVA}}</p></span>
                    </v-col>
                </v-card-text>
            </v-card>
            <v-card class="ma-4 d-flex flex-column">
                <v-card-title id="card-title" class="text-h4 text--primary">Informations du Compteur</v-card-title>
                <v-card-text class="ma-0 pa-0 d-flex flex-row">
                    <v-col class="ma-0 d-flex flex-column" >
                        <span  class="d-flex text-subtitle-2">Numéro de Compteur: <p class="ma-0 pl-2 black--text font-weight-bold">{{numCompteur}}</p></span>
                        <span  class="d-flex text-subtitle-2">FAT: <p class="ma-0 pl-2 black--text font-weight-bold">{{optionTarifaire}}</p></span>
                    </v-col>
                </v-card-text>
            </v-card>
            <v-divider class="ma-0 pa-0"></v-divider>
            <div class=" d-flex flex-column justify-content-center  mt-12">
                <h4 class="ml-6">Liste d'offres</h4>
                <b-table thead-class="textCenter" tbody-tr-class="textCenter" :busy="loadingTable" class="ma-4" :items="listDesOffre" :fields="headersDataTable"  bordered striped responsive="md" >
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="pl-4">Chargement des offres en cours...</strong>
                        </div>
                    </template>
                    <template #cell(buttonDetails)="row">
                        <b-button style="width:82.3px" size="sm" variant="info" @click="row.toggleDetails" class="mr-2">
                        Details
                        </b-button>
                        <b-button v-b-modal="row.item.id+''" size="sm" class="mr-2" variant="danger">Supprimer</b-button>
                        <b-modal  @ok="removeOffer(row.item)" ok-title="Oui" cancel-title="Non" ok-variant="danger"  :id="row.item.id+''" title="Supppression de l'offre">
                            Etes vous sur de vouloir supprimer cette offre ?
                        </b-modal>
                    </template>
                    <template #cell(dateDebut)="row">
                        <p>{{dateFormatSlash(row.item.dateDebut)}}</p>
                    </template>
                    <template #cell(dateFin)="row">
                        <p>{{dateFormatSlash(row.item.dateFin)}}</p>
                    </template>
                    <!-- detailes -->
                    <template #row-details="row">
                        <b-card v-if="row.item.typeDemande == 'ELEC'">
                            <v-form ref="formUpdateElec">
                                <b-row class="pl-2 my-4">
                                    <b-col cols="12" sm="12" md="7" lg="7" xl="7">
                                        <b-row class="text-center" align-v="center">
                                            <v-radio-group v-model="row.item.turpeInclus" row>
                                                <v-radio class="pl-4" value="oui" label="Turpe Inclus"></v-radio>
                                                <v-radio class="pr-4" value="non" label="Turpe non Inclus"></v-radio>
                                            </v-radio-group>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class='pl-4 mt-8 mb-2'>
                                    <b class="mr-4">Prix du MWh : </b>
                                </b-row>
                                <b-row class="mb-8 mt-1">
                                    <v-text-field v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4' " 
                                        outlined dense class="mt-4 mx-2" style="width:15%" v-model="row.item.HPH" label="(HPH)"
                                        suffix="€"
                                         :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4' "
                                        outlined dense class="mt-4 mx-2" style="width:15%" v-model="row.item.HCH" label="(HCH)"
                                        suffix="€"
                                         :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4' " 
                                        outlined dense class="mt-4 mx-2" style="width:15%" v-model="row.item.HPE" label="(HPE)"
                                        suffix="€"
                                         :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4' " 
                                        outlined dense class="mt-4 mx-2" style="width:15%" v-model="row.item.HCE" label="(HCE)"
                                        suffix="€"
                                         :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-if="row.item.compteurType == 'C3' " class="mt-4 mx-2" style="width:15%"
                                        suffix="€"
                                        outlined dense v-model="row.item.POINTE"  label="(P)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-if="row.item.compteurType == 'C5BASE'" class="mt-4 mx-2" style="width:15%"
                                        suffix="€"
                                        outlined dense v-model="row.item.BASE"  label="(BASE)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-if="row.item.compteurType == 'C5HP'" class="mt-4 mx-2" style="width:15%"
                                        suffix="€"
                                        outlined dense v-model="row.item.HP"  label="(HP)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-if="row.item.compteurType == 'C5HP'" class="mt-4 mx-2" style="width:15%"
                                        suffix="€"
                                        outlined dense v-model="row.item.HC"  label="(HC)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field class="mt-4 mx-2" style="width:15%" v-model="row.item.CEE"
                                        suffix="€"
                                        outlined dense label="CEE" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field class="mt-4 mx-2" style="width:15%" v-model="row.item.abo"
                                        suffix="€"
                                        outlined dense label="Abonnement mensuel" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                </b-row>

                                <b-row class="pl-4 mt-4 mb-2">
                                    <b class="mr-4">Informations de l'offre :</b>
                                </b-row>
                                <b-row class="my-4 pl-4">
                                   <v-radio-group v-model="row.item.typeCapa" row>
                                        <v-radio class="pr-4" value="CAPA" label="Cout Capacité €/MWh"></v-radio>
                                        <v-radio class="pr-4" value="COEF" label="Coefficient Capacité €/MWh"></v-radio>
                                        <v-radio class="pr-4" value="CAPAINCLUS" label="CAPA Inclus"></v-radio>
                                    </v-radio-group>
                                </b-row>
                                <b-row class="mb-8 mt-1">
                                    <v-text-field v-model="row.item.CAPA_HPH"  v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'"
                                        suffix="€"
                                        outlined dense class="mt-4 mx-2" style="width:15%"  label="(HPH)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.CAPA_HCH"  v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'"
                                        suffix="€"
                                        outlined dense class="mt-4 mx-2" style="width:15%"  label="(HCH)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.CAPA_HPE"  v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'"
                                        suffix="€"
                                        outlined dense class="mt-4 mx-2" style="width:15%"  label="(HPE)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.CAPA_HCE"  v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'"
                                       suffix="€"
                                        outlined dense class="mt-4 mx-2" style="width:15%"  label="(HCE)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.CAPA_POINTE"   v-if="row.item.compteurType == 'C3'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense style="width:15%"  label="(P)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.CAPA_BASE"  v-if="row.item.compteurType == 'C5BASE'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense style="width:15%"  label="(BASE)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.CAPA_HP"  v-if="row.item.compteurType == 'C5HP'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense style="width:15%"  label="(HP)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.CAPA_HC"  v-if="row.item.compteurType == 'C5HP'" class="mt-4 mx-2"
                                       suffix="€"
                                       outlined dense style="width:15%"  label="(HC)" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                </b-row>

                                <b-row class="pa-4">
                                    <b-button @click="modifyData(row.item, 'Elec')" variant="primary">Envoyer</b-button>
                                </b-row>
                            </v-form>
                        </b-card>
                        <b-card v-if="row.item.typeDemande == 'GAZ'">
                            <v-form ref="formUpdateGaz">
                                <b-row class='pl-4 mt-8 mb-2'>
                                    <b class="mr-4">Prix du MWh : </b>
                                </b-row>
                                <b-row class="mb-8 mt-1">
                                    <v-text-field v-model="row.item.prixMolecule"  class="ml-4 mt-4 mr-2" style="width:20%"
                                        outlined dense label="Prix de la molécule" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.abo" class="mt-4 mr-2" style="width:20%"
                                        outlined dense label="Abonnement mensuel" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.CEE" class="mt-4" style="width:20%"
                                        outlined dense label="CEE" :rules="[rules.required, rules.valeurMin]" step="0.001" type="number">
                                    </v-text-field>
                                </b-row>
                                <b-row class="pa-4">
                                    <b-button @click="modifyData(row.item, 'Gaz')" variant="primary">Envoyer</b-button>
                                </b-row>
                            </v-form>
                        </b-card>
                    </template>
                </b-table>
            </div>
            
            <v-divider class="ma-0 pa-0"></v-divider>
            <v-btn  class="ma-4 px-2" v-if="showForm == false" @click="showForm=true" color="primary">
                <b>Ajouter Nouvelle Offre</b>
            </v-btn>
            <v-card  class="ma-4 d-flex flex-column" v-if="typeDemande == 'ELEC' && showForm == true">
                <v-card-title id="card-title" class="text-h4 text--primary"> Nouvelle offre ELEC</v-card-title>
                <v-form ref="formActuelle">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="11" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                            <p class="ml-4"><b>Informations sur l'offre:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                            <v-row class="ma-1 pa-0">
                            
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3' 
                                        v-if="typeUser == 'admin'">
                                    <v-select
                                        dense
                                        required
                                        outlined dense
                                        @change="selectTypeOffre"
                                        :rules="[required('un Fournisseur valide')]"
                                        v-model="nouvelleOffreElec.fournisseur"
                                        :items="optionsFournisseurs"
                                        label="Fournisseur"
                                    ></v-select>
                                </v-col>
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                                    <v-select
                                        required
                                        outlined dense
                                        @change="selectTypeOffre"
                                        :rules="[required(`un type d'offre valide`)]"
                                        v-model="nouvelleOffreElec.typeOffre"
                                        :items="['Arenh', 'Fixe']"
                                        label="type d'offre"
                                    ></v-select>
                                </v-col>
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                                    <v-text-field
                                        required
                                        type="date"
                                        outlined dense
                                        :rules="[required('une date de début de contrat valide')]"
                                        v-model="nouvelleOffreElec.dateDebut"
                                        disabled
                                        label="Début de contrat"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                                    <v-text-field
                                        required
                                        type="date"
                                        outlined dense
                                        :rules="[required('une date de fin de contrat valide'),dateFinValide(nouvelleOffreElec.dateDebut)]"
                                        v-model="nouvelleOffreElec.dateFin"
                                        :items="listeDesAnnee"
                                        label="Fin de contrat"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols='11' sm="5" md="5" lg="5" xl='5'>
                                    <v-radio-group
                                        v-model="nouvelleOffreElec.turpeInclus"
                                        :disabled="isDisabledTurpe"
                                        row
                                        >
                                        <v-radio
                                            label="Turpe Inclus"
                                            value="oui"
                                        ></v-radio>
                                        <v-radio
                                            label="TURPE non Inclus"
                                            value="non"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- Prix -->
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <p class="ml-4"><b>Prix du MWh:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                v-if="parametreCompteur == 'C5BASE' || optionTarifaire == 'BTINFCU4'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense
                                required
                                outlined dense
                                v-model="nouvelleOffreElec.prixBASE"
                                label="(BASE)"
                                :rules="[required('un PRIX (BASE) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                min="0"
                                suffix="€"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.prixHPH"
                                label="(HPH)"
                                :rules="[required('un PRIX (HPH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && optionTarifaire == 'BTINFMU4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.prixHP"
                                label="(HP)"
                                :rules="[required('un PRIX (HP) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="parametreCompteur == 'C3' || parametreCompteur == 'C5HP'
                                    || (parametreCompteur == 'C4' && optionTarifaire != 'BTINFCU4')">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.prixHCH"
                                label="(HCH)"
                                :rules="[required('un PRIX (HCH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && optionTarifaire == 'BTINFMU4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.prixHC"
                                label="(HC)"
                                :rules="[required('un PRIX (HC) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col  cols="11" sm="3" md="2" lg="2" xl='2' v-if="parametreCompteur == 'C3' || parametreCompteur == 'C4'
                                && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.prixHPE"
                                label="(HPE)"
                                :rules="[required('un PRIX (HPE) valide')]"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col  cols="11" sm="3" md="2" lg="2" xl='2' v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') 
                                && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.prixHCE"
                                label="(HCE)"
                                :rules="[required('un PRIX (HCE) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col  cols="11" sm="3" md="2" lg="2" xl='2' v-if="parametreCompteur == 'C3' && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'">
                            <v-text-field
                                v-if="parametreCompteur == 'C3'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.prixPOINTE"
                                label="(P)"
                                :rules="[required('un PRIX (P) valide')]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                dense required
                                suffix="€"
                                outlined dense
                                v-model="nouvelleOffreElec.CEE"
                                label="CEE"
                                :rules="[required('un CEE valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                dense required
                                suffix="€"
                                outlined dense
                                v-model="nouvelleOffreElec.ABO"
                                label="Abonnement mensuel"
                                :rules="[required('une valeur valide')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <!-- coef capa -->
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <p class="ml-4"><b>Informations sur la Capacité:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        
                        <v-col cols='12' sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <v-radio-group v-model="nouvelleOffreElec.typeCapa" row class="ma-0 pa-0">
                                <v-radio
                                    label="Coût Capacité €/MWh"
                                    value="CAPA"
                                ></v-radio>
                                <v-radio
                                    label="Coefficient Capacité €/MWh"
                                    value="COEF"
                                ></v-radio>
                                <v-radio
                                    label="CAPA Inclus"
                                    value="CAPAINCLUS"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS'">
                            <v-text-field
                                v-if="parametreCompteur == 'C5BASE' || optionTarifaire == 'BTINFCU4'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.capaBASE"
                                label="(BASE)"
                                :rules="[required('un CAPA (BASE) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.capaHPH"
                                label="(HPH)"
                                :rules="[required('un CAPA (HPH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && optionTarifaire == 'BTINFMU4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.capaHP"
                                label="(HP)"
                                :rules="[required('un CAPA (HP) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS' && (parametreCompteur == 'C3' || parametreCompteur == 'C5HP'
                                    || (parametreCompteur == 'C4' && optionTarifaire != 'BTINFCU4'))">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.capaHCH"
                                label="(HCH)"
                                :rules="[required('un CAPA (HCH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP' || (parametreCompteur == 'C4' && optionTarifaire == 'BTINFMU4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.capaHC"
                                label="(HC)"
                                :rules="[required('un CAPA (HC) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS' && (parametreCompteur == 'C3' || parametreCompteur == 'C4'
                                && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4')">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.capaHPE"
                                label="(HPE)"
                                :rules="[required('un CAPA (HPE) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS' && ((parametreCompteur == 'C3' || parametreCompteur == 'C4')
                                && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4')">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4') && optionTarifaire != 'BTINFCU4' && optionTarifaire != 'BTINFMU4'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.capaHCE"
                                label="(HCE)"
                                :rules="[required('un CAPA (HCE) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS' && parametreCompteur == 'C3'">
                            <v-text-field
                                v-if="parametreCompteur == 'C3'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                dense required
                                outlined dense
                                v-model="nouvelleOffreElec.capaPOINTE"
                                label="(P)"
                                :rules="[required('un CAPA (P) valide')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-card-actions>
                    <v-row>
                        <v-col cols="10" md="10" sm="10" lg="10" xl="10">
                            <v-btn
                                color="primary"
                                class='ml-5'
                                @click="AjouterOffre(nouvelleOffreElec)"
                            >
                                Ajouter
                            </v-btn>
                            <v-btn
                                color="error"
                                class='ml-5'
                                text
                                @click="fermer"
                            >
                                Fermer
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            <v-card  class="ma-4 d-flex flex-column" v-else-if="typeDemande == 'GAZ' && showForm == true">
                <v-card-title id="card-title" class="text-h4 text--primary"> Nouvelle offre GAZ</v-card-title>
                <v-form ref="formActuelle">
                    <v-row class="ma-1 pa-0">
                        <v-col cols="11" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                            <p class="ml-4"><b>Informations de l'offre:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        <v-col cols='11' sm="4" md="3" lg="3" xl='3' 
                                        v-if="typeUser == 'admin'">
                            <v-select
                                dense
                                required
                                outlined dense
                                @change="selectTypeOffre"
                                :rules="[required('Fournisseur')]"
                                v-model="nouvelleOffreGaz.fournisseur"
                                :items="optionsFournisseurs"
                                label="Fournisseur"
                            ></v-select>
                        </v-col>
                        <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                        <v-select
                            required
                            outlined dense
                            dense
                            @change="selectTypeOffre"
                            :rules="[required('type de offre')]"
                            v-model="nouvelleOffreGaz.typeOffre"
                            :items="['Variable', 'Fixe']"
                            label="type d'offre"
                        ></v-select>
                    </v-col>
                    <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                        <v-text-field
                            required
                            type="date"
                            outlined dense
                            :rules="[required('date Debut')]"
                            v-model="nouvelleOffreGaz.dateDebut"
                            disabled
                            label="Début de contrat"
                        ></v-text-field>
                    </v-col>
                    <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                        <v-text-field
                            required
                            type="date"
                            outlined dense
                            :rules="[required('date Fin'), dateFinValide(nouvelleOffreElec.dateDebut)]"
                            v-model="nouvelleOffreGaz.dateFin"
                            :min="nouvelleOffreGaz.dateDebut"
                            :items="listeDesAnnee"
                            label="Date Fin"
                        ></v-text-field>
                    </v-col>
                    </v-row>
                    
                    <!-- Prix -->
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <p class="ml-4"><b>Prix du MWh:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                dense
                                suffix="€"
                                outlined dense
                                v-model="nouvelleOffreGaz.prixMolecule"
                                label="Prix de la molécule"
                                :rules="[required('un prix de la molécule valide')]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                suffix="€"
                                dense
                                outlined dense
                                v-model="nouvelleOffreGaz.ABO"
                                label="Abonnement mensuel"
                                :rules="[required('une valeur valide')]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                suffix="€"
                                dense
                                outlined dense
                                v-model="nouvelleOffreGaz.CEE"
                                label="CEE"
                                :rules="[required('un CEE valide')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-card-actions>
                    <v-row>
                        <v-col cols="10" md="10" sm="10" lg="10" xl="10">
                            <v-btn
                                color="primary"
                                class='ml-5'
                                @click="AjouterOffre(nouvelleOffreGaz)"
                            >
                                Ajouter
                            </v-btn>
                            <v-btn
                                color="error"
                                class='ml-5'
                                text
                                @click="fermer"
                            >
                                Fermer
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>

    import moment from 'moment'
    import axios from 'axios'
    export default{
        name: "ajouterNouvelleOffre",
        data(){
            return{
                isDisabledTurpe:false,
                errorDateDeFin : false,
                dialogAjouterOffre: false,
                messageAjouter: "",
                errorAjouterOffre: false,
                loading: false,
                dialog: false,
                loadingTable:false,
                offreModifier: null,
                headersDataTable:[
                    {key:"date",label:"Date d'ajout",sortable:true},
                    {key:"dateDebut",label:"Début de contrat",sortable:true},
                    {key:"dateFin",label:"Fin de contrat",sortable:true},
                    {key:"fournisseur",label:"Fournisseur",sortable:true},
                    {key:"compteurNumber",label:"Numéro de compteur",sortable:true},
                    {key:"compteurType",label:"Profil",sortable:true},
                    {key:"typeDemande",label:"Type d'énergie",sortable:true},
                    {key:"typeOffre",label:"Offre",sortable:true},
                    {key:"buttonDetails",label:"Actions"}
                ],
                defaultDateDebut: '',
                //////////////////////////////////////////
                showForm: false,
                linkFound: false,
                listDesOffre: [],
                societe: null,
                typeUser: localStorage.getItem('typeUser'),
                typeDemande: null,
                parametreCompteur: null,
                optionTarifaire: null,
                numCompteur: null,
                etat: null,
                listeDesAnnee:['Tout', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035'],
                nouvelleOffreElec:{
                    fournisseur: null,
                    turpeInclus: 'non',
                    dateDebut: '',
                    dateFin: '',
                    annee: null,
                    typeOffre: null,
                    prixHPH: null,
                    prixHCH: null,
                    prixHPE: null,
                    prixHCE: null,
                    prixHP: null,
                    prixHC: null,
                    prixBASE: null, 
                    prixPOINTE: null, 
                    typeCapa: 'CAPA',
                    capaHPH: null,
                    capaHCH: null,
                    capaHPE: null,
                    capaHCE: null,
                    capaHP: null,
                    capaHC: null,
                    capaBASE: null, 
                    capaPOINTE: null, 
                    CEE: null,
                    ABO: null,           
                },
                nouvelleOffreGaz:{
                    dateDebut: '',
                    dateFin: '',
                    fournisseur: null,
                    annee: null,
                    typeOffre: null,
                    prixMolecule: null,
                    CEE: null,
                    ABO: null,           
                },
                
                optionsFournisseurs: [
                    'Dyneff',
                    'EDS',
                    'EDSB',
                    'Ekwateur',
                    'Elecocite',
                    'Electricite_de_provence',
                    'Endesa',
                    'Engie',
                    'ENI',
                    'Enovos',
                    'Gaz_de_Bordeaux',
                    'GazelEnergie',
                    'Gazprom',
                    'GEG',
                    'IBERDROLA',
                    'LLUM',
                    'OHM',
                    'Picoty',
                    'Primeo',
                    'Proxelia',
                    'Selia',
                    'Synelva',
                    'Vattenfall',
                    'Wekiwi',
                ],
                rules: {
                    required: value => !!value || 'Ce champ est obligatoire.',
                    valeurMin: (v) => (v >= 0) || "Ce champ n'accepte qu'une valeur positive.",
                },
                required(fieldName) {
                    return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
                dateFinValide(dateDebut){
                    return (value) =>new Date(dateDebut).getTime() < new Date(value).getTime() || `Veuillez saisir une date de fin de contrat valide`
                },
            }
        },
        created() {
            this.verifierLaccessEtGetData()
        },
        methods: {
            closeDialogAjouterOffre(){
                this.dialogAjouterOffre = false
                this.messageAjouter = ""
                this.errorAjouterOffre = false
                this.loading = false
            },
            modifierOffreAnyWay(offre){
                this.dialogAjouterOffre = true
                this.loading = true
                this.messageAjouter = ""
                this.errorAjouterOffre = false
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT +
                    'modifierOffreAnyWay/',
                    method: 'POST',
                    data: {
                        token: this.getToken('token'),
                        item: offre,
                        typeDemande: this.typeDemande,
                    },
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.dialogAjouterOffre = false
                        this.loading = true
                        this.messageAjouter = ""
                        this.errorAjouterOffre = false
                    }else if(res.data.result==false){
                        this.messageAjouter = res.data.message
                        this.errorAjouterOffre = true
                    }else if(res.data.result=="year"){
                        this.messageAjouter = res.data.message
                        this.errorAjouterOffre = "year"
                    }else{
                        this.dialogAjouterOffre = false
                        this.loading = true
                        this.messageAjouter = ""
                        this.errorAjouterOffre = false
                        alert('permission')
                    }
                    this.verifierLaccessEtGetData()
                    this.fermer()
                })
                .catch((err)=>{
                    console.log(err)
                    this.dialogAjouterOffre = false
                    this.loading = true
                    this.messageAjouter = ""
                    this.errorAjouterOffre = false
                    this.fermer()
                })
            },
            arrayRemove(arr, value) {
                return arr.filter(function(ele){ 
                    return ele != value; 
                });
            },
            selectTypeOffre(){this.isDisabledTurpe = false
                var trouver = false;
                var typeOffre;
                var fournisseur;
                var dateFin = new Date('1990-01-01');
                if(this.typeDemande == "GAZ"){
                    fournisseur = this.nouvelleOffreGaz.fournisseur
                    typeOffre = this.nouvelleOffreGaz.typeOffre
                }else{
                    fournisseur = this.nouvelleOffreElec.fournisseur
                    typeOffre = this.nouvelleOffreElec.typeOffre

                }
                for (var i=0; i<this.listDesOffre.length; i++){
                    if(this.listDesOffre[i]['typeOffre'] == typeOffre && this.listDesOffre[i]['fournisseur'] == fournisseur){
                        
                        var currentDateFin = new Date(this.listDesOffre[i]['dateFin'])
                        if (currentDateFin > dateFin){
                            trouver = true
                            dateFin = currentDateFin;
                            if(this.typeDemande == "GAZ"){
                                this.nouvelleOffreGaz.dateDebut = moment(dateFin.setDate(dateFin.getDate() + 1)).format('YYYY-MM-DD');
                            }else{
                                this.nouvelleOffreElec.dateDebut = moment(dateFin.setDate(dateFin.getDate() + 1)).format('YYYY-MM-DD');
                                if (this.listDesOffre[i]['turpeInclus'] == "oui"){
                                    this.nouvelleOffreElec.turpeInclus = 'oui'
                                    this.isDisabledTurpe = true
                                }else{
                                    this.nouvelleOffreElec.turpeInclus = 'non'
                                    this.isDisabledTurpe = true
                                }
                            }
                        }
                    }
                }
                if(trouver == false){ 
                    if(this.typeDemande == "GAZ"){
                        this.nouvelleOffreGaz.dateDebut =this.defaultDateDebut;
                    }else{
                        this.nouvelleOffreElec.dateDebut = this.defaultDateDebut;
                    }
                }
            },
            dateFormatSlash(date){
                return date.split('-').reverse().join('/')
            },
            fermer(){
                this.loadingTable = false;
                this.showForm = false
                this.nouvelleOffreElec.fournisseur= null
                this.nouvelleOffreElec.turpeInclus= 'non'
                this.nouvelleOffreElec.annee= null
                this.nouvelleOffreElec.typeOffre= null
                this.nouvelleOffreElec.dateDebut= ''
                this.nouvelleOffreElec.dateFin= ''
                this.nouvelleOffreElec.prixHPH= null
                this.nouvelleOffreElec.prixHCH= null
                this.nouvelleOffreElec.prixHPE= null
                this.nouvelleOffreElec.prixHCE= null
                this.nouvelleOffreElec.prixHP= null
                this.nouvelleOffreElec.prixHC= null
                this.nouvelleOffreElec.prixBASE= null 
                this.nouvelleOffreElec.prixPOINTE= null 
                this.nouvelleOffreElec.typeCapa= 'CAPA'
                this.nouvelleOffreElec.capaHPH= null
                this.nouvelleOffreElec.capaHCH= null
                this.nouvelleOffreElec.capaHPE= null
                this.nouvelleOffreElec.capaHCE= null
                this.nouvelleOffreElec.capaHP= null
                this.nouvelleOffreElec.capaHC= null
                this.nouvelleOffreElec.capaBASE= null
                this.nouvelleOffreElec.capaPOINTE= null
                this.nouvelleOffreElec.CEE= null
                this.nouvelleOffreElec.ABO= null
                this.nouvelleOffreGaz.fournisseur= null
                this.nouvelleOffreGaz.dateDebut= ''
                this.nouvelleOffreGaz.dateFin= ''
                this.nouvelleOffreGaz.annee= null
                this.nouvelleOffreGaz.typeOffre= null
                this.nouvelleOffreGaz.prixMolecule= null
                this.nouvelleOffreGaz.CEE= null
                this.nouvelleOffreGaz.ABO= null
            },
            verifierLaccessEtGetData(){
                this.dialog = true
                this.loading = true
                this.loadingTable = true
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idLink', this.$route.query.id)
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT +
                    'getToutLesOffreById/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result == true){
                        this.linkFound = true
                        this.listDesOffre = res.data.resulatFinal.listsDesOffres
                        this.numCompteur= res.data.resulatFinal.numCompteur
                        this.etat= res.data.resulatFinal.etat
                        this.societe = res.data.resulatFinal.societe
                        this.typeDemande = res.data.resulatFinal.typeDemande
                        if(this.typeDemande=='ELEC'){
                            this.loadingTable = false
                            this.nouvelleOffreElec.dateDebut = res.data.resulatFinal.dateDebut
                            this.defaultDateDebut = res.data.resulatFinal.dateDebut
                        }else{
                            this.loadingTable = false
                            this.nouvelleOffreGaz.dateDebut = res.data.resulatFinal.dateDebut
                            this.defaultDateDebut = res.data.resulatFinal.dateDebut
                        }
                        this.parametreCompteur = res.data.resulatFinal.parametreCompteur
                        this.optionTarifaire = res.data.resulatFinal.optionTarifaire
                    }else if(res.data.result == false){
                        this.loadingTable = false
                        this.linkFound = false
                        this.listDesOffre = []
                        this.numCompteur= null
                        this.etat= null
                        this.societe = null
                        this.typeDemande = null
                        this.parametreCompteur = null
                        this.optionTarifaire =null
                    }else{
                        this.loadingTable = false
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                    this.dialogAjouterOffre = false;    
                    this.loadingTable = false
                    this.dialog = false
                    this.loading = false
                }).catch((err)=>{
                    console.log(err)
                    this.loadingTable = false
                    this.dialog = false
                    this.loading = false
                })
            },
            modifyData(item, typeDemande){
                this.offreModifier = item
                this.dialogAjouterOffre = true
                this.messageAjouter = ""
                this.errorAjouterOffre = false
                this.loading = true
                if(typeDemande=="Gaz"){
                    this.$refs.formUpdateGaz.validate();
                    if (this.$refs.formUpdateGaz.validate()) {
                        var data = {
                            token: this.getToken('token'),
                            item: item,
                            typeDemande: 'GAZ',
                        }
                        axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + "modifierOffreExtern/",
                            method: "POST",
                            data: data,
                        })
                        .then((res) => {
                            if (res.data.result === "permission") {
                                localStorage.removeItem("vendeurName");
                                localStorage.removeItem("typeUser");
                                localStorage.removeItem("token");
                                this.$router.push("/login");
                            }
                            if (res.data.result == true) {
                                this.verifierLaccessEtGetData()
                                alert("mise a jour effectuée avec succès")
                            }
                        })
                    }
                }else if(typeDemande=="Elec"){
                    this.$refs.formUpdateElec.validate();
                    if (this.$refs.formUpdateElec.validate()) {
                        var data = {
                            token: this.getToken('token'),
                            item: item,
                            typeDemande: 'ELEC',
                        }
                        axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + "modifierOffreExtern/",
                            method: "POST",
                            data: data,
                        })
                            .then((res) => {
                            if (res.data.result === "permission") {
                                localStorage.removeItem("vendeurName");
                                localStorage.removeItem("typeUser");
                                localStorage.removeItem("token");
                                this.$router.push("/login");
                            }
                            if (res.data.result == true) {
                                this.dialogAjouterOffre = true
                                this.messageAjouter = "Offre modifiée avec succès !"
                                this.errorAjouterOffre = false
                                this.loading = false
                                this.verifierLaccessEtGetData()
                            }else if(res.data.result=="capa"){
                                this.dialogAjouterOffre = true
                                this.messageAjouter = "Modification valeur TURPE pour cette offre et toutes offres ayant le même type d'énergie et le même fournisseur"
                                this.errorAjouterOffre = true
                                this.loading = false
                                this.loadingTable = false
                            }
                        }).catch((err)=>{
                            
                                this.dialogAjouterOffre = true
                                this.messageAjouter = "Une errreur est survenue ! Veuillez réessayer"
                                this.errorAjouterOffre = false
                                this.loading = false
                        })
                    }
                }
            },
            removeOffer(item){
                var data = {
                    token: this.getToken('token'),
                    item: item,
                }
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + "deleteOffreExtern/",
                    method: "POST",
                    data: data,
                })
                .then((res) => {
                    if (res.data.result === "permission") {
                        localStorage.removeItem("vendeurName");
                        localStorage.removeItem("typeUser");
                        localStorage.removeItem("token");
                        this.$router.push("/login");
                    }
                    if (res.data.result == true) {
                        this.verifierLaccessEtGetData()
                    }
                })
            },
            AjouterOffre(offre){
                this.$refs.formActuelle.validate()
                if(this.$refs.formActuelle.validate()){
                    
                    this.dialogAjouterOffre = true
                    this.loadingTable = true
                    this.messageAjouter = ""
                    this.errorAjouterOffre = false
                    axios({
                        url: process.env.VUE_APP_URL_API_CLIENT +
                        'AjouterOffre/',
                        method: 'POST',
                        data: {
                            token: this.getToken('token'),
                            idLink: this.$route.query.id,
                            offre: offre,
                            typeDemande: this.typeDemande,
                            societePart:"SMART",
                        },
                    })
                    .then((res) => {
                        if(res.data.result==true){
                            this.dialogAjouterOffre = false
                            this.loadingTable = false
                            this.messageAjouter = ""
                            this.errorAjouterOffre = false
                        }else if(res.data.result==false){
                            this.messageAjouter = res.data.message
                            this.errorAjouterOffre = true
                            this.loadingTable = false
                        }else{
                            this.dialogAjouterOffre = false
                            this.loadingTable = false
                            this.messageAjouter = ""
                            this.errorAjouterOffre = false
                            alert('permission')
                        }
                        this.loadingTable = false;
                        this.verifierLaccessEtGetData()
                        this.fermer()
                    })
                    .catch((err)=>{
                        console.log(err)
                        this.dialogAjouterOffre = false
                        this.loadingTable = false
                        this.messageAjouter = ""
                        this.errorAjouterOffre = false
                        this.fermer()
                    })
                }
            },
            desactiverLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'disactiverLink/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result == true){
                        alert("Lien désactivé")
                        this.verifierLaccessEtGetData()
                    }else if(res.data.result == false){
                        alert("Impossible de désactiver ce lien")
                        this.verifierLaccessEtGetData()
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })

            },
            activerLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'activerLink/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result == true){
                        alert("Lien activé")
                        this.verifierLaccessEtGetData()
                    }else if(res.data.result == false){
                        alert("Impossible d'activer ce lien")
                        this.verifierLaccessEtGetData()
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
        }
    }
</script>
<style scoped>
    @media only screen and (max-width:800px){
        #card-title{
            font-size:1.5em !important;
        }
    }

</style>
<style scoped>
    @media only screen and (max-width:800px){
        .container-responsive{
            padding-left:5px !important;
            padding-right:5px !important;
        }
    }
</style>
<style scoped>
    @media only screen and (max-width:800px){
        .container2-responsive{
            padding-left:5px !important;
            padding-right:5px !important;
            padding-top:0px !important;
        }
    }
</style>
<style scoped>
@media only screen and (max-width:800px){
     .v-application .d-flex{
        flex-wrap:wrap !important;
    }
}
</style>
<style scoped>
@media only screen and (max-width:800px){
    .v-application .text-subtitle-2{
        font-size:.8rem !important;
        font-weight:300;
    }
}
</style>
<style scoped>
@media only screen and (max-width:800px){
    .v-application .pl-2{
        padding-left:5px !important;
    }
}
</style>
<style scoped>
@media only screen and (max-width:800px){
    .v-application .font-weight-bold{
        font-size: x-small;
    }
}
</style>
<style scoped>
::v-deep .v-input--selection-controls__input + .v-label{
   margin-bottom:0px !important;
}
</style>
